import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

import HomebuyingGuideLayout from '../../components/Layout/HomebuyingGuide'
import Hero from '../../components/HomebuyingGuidePage/Hero'
import HomebuyingGuide from '../../components/HomebuyingGuidePage/HomebuyingGuide'
import NoData from '../../components/NoData'
import SEO from '../../components/SEO/SEO'

const HomeBuyingGuidePage = (): ReactElement => {
  const featuredHomeBuyingGuide = useStaticQuery(graphql`
    query featuredHomeBuyingGuide {
      allSitePage(filter: { context: { category: { eq: "home-buying-guide" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                type
                description
                title
                updated
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = featuredHomeBuyingGuide.allSitePage.edges
  const featured = data.filter((res: any) => res.node.context.data.feature === true)
  const noData = data.length > 0 ? false : true

  return (
    <HomebuyingGuideLayout noData={noData}>
      <SEO title="Homebuying Guide - ALISI | Ayala Land" />
      {!noData ? (
        <>
          <Hero data={featured.length > 0 ? featured[0].node : data[0].node} />
          <HomebuyingGuide />
        </>
      ) : (
        <NoData withBackground={true} title="home buying guide" />
      )}
    </HomebuyingGuideLayout>
  )
}

export default HomeBuyingGuidePage
