import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useState, useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FacebookShareButton, EmailShareButton, TwitterShareButton } from 'react-share'
import imagesLoaded from 'imagesloaded'

import './HomebuyingGuide.scss'
import ShareIcon from '../../../assets/icons/ShareIcon'
import { HomebuyingGuideTypes } from '../data'
import MailIcon from '../../../assets/icons/MailIcon'
import ChainIcon from '../../../assets/icons/Chain'

const HomebuyingGuide = (): JSX.Element => {
  const homeBuyingGuide = useStaticQuery(graphql`
    query homeBuyingGuide {
      allSitePage(filter: { context: { category: { eq: "home-buying-guide" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                type
                description
                title
                updated
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)
  const [count, setCount] = useState(5)
  const [data, setData] = useState<any>(null)
  const [shareCount, setShareCount] = useState(-1)
  const [copied, setCopied] = useState(false)
  const [url, setURL] = useState<string>('')

  const handleShare = (index: number): void => {
    setShareCount(index)
    setCopied(false)
  }

  const handleClose = (): void => {
    setShareCount(-1)
    setCopied(false)
  }

  useEffect(() => {
    const resizeMasonryItem = (item: any): void => {
      const grid = document.getElementsByClassName('masonry')[0]
      if (grid) {
        const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
          rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')),
          gridImagesAsContent = item.querySelector('img.masonry-content')

        const rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap))

        item.style.gridRowEnd = 'span ' + rowSpan
        if (gridImagesAsContent) {
          item.querySelector('img.masonry-content').style.height = item.getBoundingClientRect().height + 'px'
        }
      }
    }

    const resizeAllMasonryItems = () => {
      const allItems = document.querySelectorAll('.masonry-item')

      if (allItems) {
        for (let i = 0; i > allItems.length; i++) {
          resizeMasonryItem(allItems[i])
        }
      }
    }

    const waitForImages = () => {
      const allItems = document.querySelectorAll('.masonry-item')
      if (allItems) {
        for (let i = 0; i < allItems.length; i++) {
          imagesLoaded(allItems[i], function (instance: any) {
            let item = instance.elements[0]
            resizeMasonryItem(item)
          })
        }
      }
    }

    const masonryEvents = ['load', 'resize']
    masonryEvents.forEach(function (event) {
      window.addEventListener(event, resizeAllMasonryItems)
    })

    const fetchData = () => {
      let tempData: HomebuyingGuideTypes[] = []
      homeBuyingGuide.allSitePage.edges.map((guide: HomebuyingGuideTypes) => {
        if (!guide.feature) {
          tempData.push(guide)
        }
      })
      setData(tempData)
    }

    waitForImages()
    if (!data) {
      fetchData()
    }
  }, [data, count])

  const handleSeeMore = () => {
    setCount(count + 6)
  }

  useEffect(() => {
    setURL(window.location.origin)
  }, [])

  return (
    <div className="homebuying-guide-page-container">
      <div className="homebuying-guide-page-masonry-container">
        <div className="masonry-wrapper">
          <div className="masonry">
            {data &&
              data
                ?.sort((a: any, b: any) => {
                  return new Date(b.node.context.data.updated).getTime() - new Date(a.node.context.data.updated).getTime()
                })
                .map((guide: any, index: number) => {
                  if (index <= count) {
                    return (
                      <div className="masonry-item fade-in-bottom" key={index}>
                        <div className="masonry-content">
                          <Link to={guide.node.path ? guide.node.path : ''}>
                            <img src={guide.node.context.data.featuredMedia[0].media} alt="Dummy Image" />
                            {guide.node.context.data.type ? (
                              <div className="masonry-type-container">
                                <div className="masonry-type-text">{guide.node.context.data.type}</div>
                                <div className="masonry-type-filler" />
                              </div>
                            ) : null}
                            <h3 className="masonry-title">{guide.node.context.data.title}</h3>
                            <span className="masonry-date">{`Updated as of ${guide.node.context.data.updated}`}</span>
                          </Link>

                          <div className="masonry-share-footer">
                            <Link to={guide.node.path ? guide.node.path : ''}>
                              <div className="discover">Discover</div>
                            </Link>
                            {shareCount === index ? (
                              <div className="share-active fade-in">
                                {copied ? (
                                  <>
                                    <div className="icon-container copied fade-in-bottom">
                                      <span>Copied to clipboard!</span>
                                    </div>
                                    <div className="share-icon-container" onClick={() => handleClose()}>
                                      <i className={`la la-close`} />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <FacebookShareButton url={`${url}${guide.node.path}`}>
                                      <div className="icon-container">
                                        <i className={`la la-facebook`} />
                                      </div>
                                    </FacebookShareButton>

                                    <TwitterShareButton url={`${url}${guide.node.path}`}>
                                      <div className="icon-container">
                                        <i className={`la la-twitter`} />
                                      </div>
                                    </TwitterShareButton>

                                    <EmailShareButton url={`${url}${guide.node.path}`}>
                                      <div className="icon-container">
                                        <MailIcon />
                                      </div>
                                    </EmailShareButton>

                                    <CopyToClipboard text={`${url}${guide.node.path}`} onCopy={() => setCopied(true)}>
                                      <div className="icon-container">
                                        <ChainIcon />
                                      </div>
                                    </CopyToClipboard>

                                    <div className="close-icon-container" onClick={() => setShareCount(-1)}>
                                      <i className={`la la-close`} />
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div className="share" onClick={() => handleShare(index)}>
                                Share
                                <ShareIcon />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
          </div>
        </div>

        {data && count + 1 < data.length ? (
          <div className="masonry-button" onClick={handleSeeMore}>
            <div>See More Guide</div>
          </div>
        ) : data && data.length > 6 ? (
          <div className="masonry-footer">
            <span>End Results</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default HomebuyingGuide
